import { projectStorage } from '@/firebase/config';

import { ref } from 'vue';

const useStorage = () => {
    const error = ref(null);

    const getSubscriptionDocumentURL = async (investorId, investmentId) => {
        let filePath = `subscriptionAgreements/${investorId}/${investmentId}.pdf`;
        let docRef = projectStorage.ref(filePath);
        try {
            let res = await docRef.getDownloadURL();
            error.value = "";
            return res;
        } catch (err) {
            error.value = `Subscription document is not available at this time.`;
            return;
        }
    };

    return { error, getSubscriptionDocumentURL };
};

export default useStorage;